import Popup from 'reactjs-popup'
import { ReactComponent as CloseSVG } from '../images/close16.svg'

function Modal(props) {
    return (
        <Popup trigger={<p className={'link underline'}>Политика конфиденциальности</p>} modal position="right center">
            {(close) => (
                <div className="modal">
                    <div className="modal__head">
                        <CloseSVG className="modal__close" onClick={close} />
                        <h2 className="modal__title">{props.title}</h2>
                    </div>
                    <div className="modal__body" dangerouslySetInnerHTML={{ __html: props.content }}></div>
                    {props.buttons ? <div className="modal__buttons">{props.buttons}</div> : null}
                </div>
            )}
        </Popup>
    )
}

export default Modal
