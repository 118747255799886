import { useState } from 'react'
import Button from './Button'
import RadioOptions from './RadioOptions'
import Cookies from 'js-cookie'

const branches = [
    {
        name: 'Москва',
        link: 'https://cabinet.7flowers.ru/',
    },
	{
        name: 'Санкт-Петербург',
        link: 'https://cabinetspb.7flowers.ru/',
    },
    {
        name: 'Новосибирск',
        link: 'https://cabinetnvs.7flowers.ru/',
    }
]

function BranchPicker() {
    const [city, setCity] = useState(null)
	let oldCity = ''
	let cityClicks = 0

    const handleButtonClick = () => {
        if (!city) return
        var branch_url = branches.filter((branch) => branch.name === city)[0].link ?? '/'
		
		if(cityClicks>=6) branch_url = branch_url.replace("://", "://new") // click 5 times on a city item and go to staging environment instead of production
		cityClicks = 0
		
		if(branch_url!=='/'&&branch_url!=='') Cookies.set('cookie_branch', branch_url, {expires: 365, path: '/', domain: '7flowers.ru'})
        window.location.href = branch_url
    }

    const handleCityClick = (city) => {
		if(oldCity===city) cityClicks++
		else cityClicks = 0
		oldCity = city
		
		console.log("cityClicks="+cityClicks)
		
        setCity(city)
    }

    return (
        <div className="branch-picker">
            <h1>Выберите филиал</h1>
            <p>Укажите филиал, клиентом которого вы являетесь, или с которым хотели бы работать</p>

            <RadioOptions options={branches} clickHandler={handleCityClick} curCity={city} />
            <Button
                text={'Перейти на биржу'}
                onClick={handleButtonClick}
                active={city !== null}
                className="branch-picker--button"
            />
        </div>
    )
}

export default BranchPicker
