import { ReactComponent as TelegramSVG } from '../images/social_icons/TG.svg'
import { ReactComponent as YoutubeSVG } from '../images/social_icons/Youtube.svg'
import { ReactComponent as VkontakteSVG } from '../images/social_icons/VK.svg'
import { ReactComponent as OkSVG } from '../images/social_icons/ok.svg'
import Image from './Image'
import Modal from './Modal'

const privacyPolicy = {
    title: 'ПОЛИТИКА СОХРАНЕНИЯ И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ',
    content: `<div class="modal__body-inner"><p>Настоящая политика конфиденциальности информации и защиты персональных данных разработана в соответствии с
    требованиями Федеральных законов от 27.07.2006 N 152-ФЗ "О персональных данных" и от 27.07.2006 N 149-ФЗ "Об
    информации, информационных технологиях и о защите информации". В данной политике применяются понятия,
    содержащиеся в вышеуказанных нормативных актах, в частности, персональные данные – любая информация, относящаяся
    прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных) и
    провозглашается обязательное выполнение требований лицами, получившим доступ к определенной информации, о ее
    конфиденциальности, т.е. невозможности передачи такой информации третьим лицам без согласия ее обладателя.
    Настоящим подтверждаем, что любая информация, добровольно и осознанно предоставляемая нашими покупателями при
    оформлении заказа в интернет-магазине, необходима исключительно для исполнения интернет-магазином заказа.
    Интернет-магазин, осуществляющий по мере необходимости обработку персональных данных покупателей, принял
    достаточные организационные и технические меры для защиты персональных данных от неправомерного или случайного
    доступа к ним или их уничтожения, изменения, блокирования, копирования, распространения, а также от иных
    неправомерных действий. Обработка персональных данных осуществляется в соответствии с принципами обработки
    персональных данных, установленных действующим законодательством, их cодержание и объем соответствует заявленным
    целям обработки, а сроки хранения осуществляются не дольше, чем этого требуют цели обработки персональных
    данных. Возможна обработка персональных данных покупателя с целью статистических обработок, маркетинговых
    исследований, повышения эффективности обслуживания каждого покупателя и при наличии его согласия на
    предоставление ему эксклюзивной информации о специальных предложениях, новинках и других маркетинговых
    материалов. В данном документе перечислена информация, которую мы получаем и собираем при пользовании Вами сайта
    <strong>7flowers.ru</strong>, что поможет Вам принять осознанное решение в отношении предоставляемой нам
    информации.</p>
    <p>Мы осознаем степень важности сохранения конфиденциальности информации. В данном документе перечислена информация,
        которую мы получаем и собираем при пользовании Вами сайтом <strong>7flowers.ru</strong>. Данные сведения помогут
        Вам принять осознанное решение в отношении предоставляемой нам личной информации.</p>
    <br>
    <p>Идентификация посетителей сайта</p>
    <br>
    <p>На сайте используется добровольная регистрация посетителей. Каждый, кто заполняет
        любую размещенную на сайте форму, оставляет адрес своей электронной почты, телефон, а также имя и фамилию в
        некоторых случаях. Вы в праве отказаться от предоставления личной информации, в этом случае регистрация на
        сайте, участие в проводимых компанией мероприятиях и пользование некоторыми услугами и продуктами сайта <strong>7flowers.ru</strong>
        будет невозможным.</p>
    <p>Мы гарантируем безопасность всех Ваших персональных данных от неправомерного или случайного доступа к ним
        третьими лицами, уничтожения, изменения, блокирования, копирования, предоставления, распространения, а также от
        иных неправомерных действий, принимая необходимые правовые, организационные и технические меры для их
        защиты.</p>
    <br>
    <p>Использование телефонного номера</p>
    <br>
    <p>Указанный Вами при регистрации телефонный номер недоступен другим посетителям
        сайта или третьим лицам. Он может быть использован для информирования об акциях проекта
        <strong>7flowers.ru</strong> с помощью звонков или смс. Вы имеете право отказаться от получения повторных
        звонков и смс сообщений, сообщив об этом сотруднику компании.</p>
    <br>
    <p>Использование электронной почты</p>
    <br>
    <p>Адрес электронной почты, указанный вами при регистрации, недоступен другим
        посетителям сайта. Мы оставляем за собой право хранить письма, которые Вы нам отправляете, для ответов на
        вопросы и совершенствования службы поддержки. Также Ваша электронная почта может быть использована для
        информационных и других рассылок с сайта <strong>7flowers.ru</strong>. В свою очередь, Вы имеете право
        отписаться от получения рассылок в любой момент.</p>
    Cookie (Куки)<p>Как правило, при посещении сайтов, на Ваш компьютер отправляются один или несколько файлов cookie.
        Это небольшой файл, в котором содержится набор символов — с его помощью сайт идентифицирует браузер, который Вы
        используете, для корректного отображения всей информации. При регистрации на сайте на ваш компьютер могут
        отправляться дополнительные файлы cookie, позволяющие избежать при следующем визите ввода имени пользователя и,
        возможно, пароля. Вы всегда можете стереть их по окончании сеанса посещения сайта, если по какой-то причине
        используете общедоступный компьютер (в этом случае вам обязательно нужно очистить кэш браузера). Мы используем
        файлы cookie для повышения качества услуг, путем сохранения пользовательских настроек и отслеживания текущих
        тенденций в действиях посетителей сайта, например, при выполнении поиска.<br>Файлы cookie также позволяют нам
        определять, какие наши рекламные объявления или предложения с большей вероятностью понравятся вам, и
        демонстрировать их вам при использовании онлайн-сервисов или отправлять в анонсах и маркетинговых сообщениях.
        Кроме того, мы можем использовать файлы cookie и другие технологии для отслеживания отклика на нашу
        интернет-рекламу и маркетинговые сообщения.<br>Большинство браузеров изначально настроены на приём файлов
        cookie, но Вы всегда можете полностью запретить использование файлов cookie или настроить показ уведомлений об
        их отправке. В свою очередь, информируем Вас о том, что без файлов cookie некоторые функции сайта могут работать
        некорректно.</p>
    <br>
    <p>Протоколирование</p>
    <br>
    <p>При каждом заходе на сайт пользователем наши серверы автоматически записывают информацию, которую
        Ваш браузер передает при посещении любых веб-страниц. Как правило, эта информация включает запрашиваемую
        веб-страницу, IP-адрес компьютера, с которого осуществлен заход, тип браузера, языковые настройки браузера, дату
        и время запроса, а также один или несколько файлов cookie, которые позволяют точно идентифицировать Ваш
        браузер.</p>
    <br>
    <p>Ссылки</p>
    <br>
    <p>Размещаемые на сайте ссылки могут быть в таком формате, который позволяет отслеживать, пользуются ли ими
        посетители. Эта информация применяется для повышения качества нашего ресурса.</p>
    <br>
    <p>Изменения в политике сохранения конфиденциальной информации</p>
    <br>
    <p>Обращаем Ваше внимание на то, что политика сохранения
        конфиденциальной информации может периодически изменяться. Все изменения в политике сохранения конфиденциальной
        информации будут доступны на этой странице.</p>
    <p>Общество с ограниченной ответственностью «Цветочный альянс». ОГРН 1147746567913. Адрес местонахождения 117218, г.
        Москва, ул. Дмитрия Ульянова, д. 42</p></div>`,
}

function Footer() {
    return (
        <footer className="footer">
            <div className={'footer__social-link'}>
                <ul>
                    <li>
                        <a href="https://t.me/sevenflowersmsk" target="_blank" rel="noreferrer">
                            <TelegramSVG />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/user/7FLOWERSrus" target="_blank" rel="noreferrer">
                            <YoutubeSVG />
                        </a>
                    </li>
                    <li>
                        <a href="https://vk.com/7flowersonline" target="_blank" rel="noreferrer">
                            <VkontakteSVG />
                        </a>
                    </li>
                    <li>
                        <a href="https://ok.ru/groupsemtsvetov" target="_blank" rel="noreferrer">
                            <OkSVG />
                        </a>
                    </li>
                </ul>
            </div>

            <div className={'footer__design-author'}>
                <a href="https://siberian.pro/" target="_blank" rel="noreferrer">
                    Дизайн разработан
                    <Image src="./images/Siberian.png" />
                </a>
            </div>

            <span className={'footer__divider'} />

            <div className={'footer__officces'}>
                <ul>
                    <li>
                        <a href="tel:74957375858">
                            Москва и СПб
                            <br />
                            <span>+7 (495) 737-58-58</span>
                        </a>
                    </li>
                    <li>
                        <a href="tel:78003337573">
                            Вся Россия
                            <br />
                            <span>+7-800-333-75-73</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.7flowers.ru/" target="_blank" rel="noreferrer">
                            О компании
                            <br />
                            <span className="underline">7flowers.ru</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="footer__contacts">
                <div className={'footer__privacy-policy'}>
                    <Modal
                        title={privacyPolicy.title}
                        content={privacyPolicy.content}
                        buttons={<></>}
                        fullscreen={true}
                        adaptive={true}
                    />
                </div>

                <div className={'footer__copyright'}>© {new Date().getFullYear()} «7ЦВЕТОВ»</div>
            </div>
        </footer>
    )
}

export default Footer
