import RadioOption from './RadioOption'

function RadioOptions({ options, clickHandler, curCity }) {
    return (
        <ul className="radio-options">
            {options.map((option) => (
                <RadioOption key={option.name} name={option.name} clickHandler={clickHandler} curCity={curCity} />
            ))}
        </ul>
    )
}

export default RadioOptions
