import BranchPicker from './BranchPicker'
import Image from './Image'
import Cookies from 'js-cookie'

const urlParams = new URLSearchParams(window.location.search)
const pushToken = urlParams.get('push_token') ?? false
const resetBranch = urlParams.get('reset_branch') ?? false
var branchRemembered = Cookies.get('cookie_branch') ?? false

if (pushToken) Cookies.set('cookie_mobile_push_token', pushToken, {expires: 365, path: '/', domain: '7flowers.ru'})
	
if (resetBranch) {
	branchRemembered = '';
	Cookies.set('cookie_branch', '', {expires: 365, path: '/', domain: '7flowers.ru'});
}

function Main() {
	if(branchRemembered && branchRemembered!=='') {
		document.location = branchRemembered
		return null
	} else return (
        <main className="main">
            <BranchPicker />
            <Image src={'images/Searching.svg'} className="main-image" />
        </main>
    )
}

export default Main
