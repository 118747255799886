function RadioOption({ name, clickHandler, curCity }) {
    const isChecked = curCity === name
    return (
        <li className="radio-option" onClick={() => clickHandler(name)}>
            <label className="container">
                {name}
                <input type="radio" value={name} checked={isChecked} />
                <span className="checkmark"></span>
            </label>
        </li>
    )
}

export default RadioOption
